<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="人员选择"
    width="70%"
    destroy-on-close
  >
    <div class="top_select">
      <div>
        <el-input
          placeholder="请输入姓名"
          v-model="listQuery.fuzzy_name"
          style="width: 160px"
          size="small"
          @keyup.enter="getList"
        />
        <el-button type="primary" size="small" @click="getList" icon="Search">
          搜索
        </el-button>
        <el-select
          v-model="listQuery.active_status"
          placeholder="请选择状态"
          size="small"
          style="width:130px"
          @change="getList"
        >
          <el-option label="全部" :value="2"> </el-option>
          <el-option label="已激活" :value="1"> </el-option>
          <el-option label="未激活" :value="0"> </el-option>
          <el-option label="已过期" :value="98"> </el-option>
        </el-select>
        <span style="color: red;"> *请先上传通讯录同步人员信息</span>
      </div>
      <div>
        注: 已使用激活码
        <span style="color:#17a2b8">{{ activedCount }}</span> 个，剩余激活码
        <span style="color:orange">{{ unactivedCount }}</span> 个
        <el-button
          type="success"
          size="small"
          @click="activeUser"
          :disabled="unactivedCount == 0"
        >
          激活账号
        </el-button>
      </div>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="contentStyleObj"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column
        prop="realName"
        label="姓名"
        min-width="150"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="activeStatus"
        label="激活状态"
        min-width="150"
        align="center"
        show-overflow-tooltip
      >
        <template #default="scope">
          <span v-if="scope.row.activeStatus == 1" style="color:green"
            >已激活</span
          >
          <span v-else-if="scope.row.activeStatus == 99" style="color:orange"
            >已离职待重新分配</span
          >
          <span v-else-if="scope.row.activeStatus == 98" style="color:red"
            >已过期</span
          >
          <span v-else>未激活</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="activeTime"
        label="激活时间"
        min-width="180"
        align="center"
        show-overflow-tooltip
      >
        <template #default="scope">
          <span v-if="scope.row.activeTime && scope.row.activeTime != '1970-01-01T08:00:00+08:00'">{{
            $parseTime(scope.row.activeTime)
          }}</span>
          <span v-else>
            --
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="expire_time"
        label="过期时间"
        min-width="180"
        align="center"
        show-overflow-tooltip
      >
        <template #default="scope">
          <span v-if="scope.row.expire_time && scope.row.expire_time != '1970-01-01T08:00:00+08:00'">
            {{ $parseTime(scope.row.expire_time) }}</span
          >
          <span v-else>
            --
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="accountType"
        label="账号类型"
        min-width="150"
        align="center"
        show-overflow-tooltip
      >
        <template #default="scope">
          <span v-if="scope.row.accountType == 1">基础账号</span>
          <span v-if="scope.row.accountType == 2">互通账号</span>
          <span v-else>未知状态</span>
        </template>
      </el-table-column>
    </el-table>
    <!--  <div class="pagination" style="margin-top: 5px">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div> -->
    <div class="top_select" style="margin: 0;margin-top: 20px;">
      <div></div>
      <div>
        <el-button type="primary" @click="freshGroup" size="small">
          刷新群聊
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  qywxGetCorpDeptList,
  qywxRefresh,
  activateAccount,
} from "@/api/qywx.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      listQuery: {
        // page: 1,
        // limit: 20,
        active_status: 2,
      },
      list: [],
      contentStyleObj: {},
      sels: [],
      total: 0,
      loading: false,
      ids: [],
      activedCount: 0,
      unactivedCount: 0,
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(370);
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.loading = true;
      this.dialogFormVisible = true;
      qywxGetCorpDeptList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.data;
          this.activedCount = res.data.data.activedCount;
          this.unactivedCount = res.data.data.unactivedCount;
          //this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.sels = e;
    },
    //刷新群聊
    freshGroup() {
      this.loading = true;
      qywxRefresh({
        userIds: [],
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage(res.data.data.data);
          this.dialogFormVisible = false;
          this.$emit("success");
        }
      });
    },
    //激活账号
    activeUser() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择人员", 1);
        return;
      }
      let status = false;
      this.sels.map((v) => {
        if (v.activeStatus == 1) {
          status = true;
          return;
        }
      });
      if (status) {
        this.$qzfMessage("存在已激活人员", 1);
        return;
      }
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.userid);
      });
      this.loading = true;
      activateAccount({
        userIds: ids,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("激活成功");
          this.getList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
