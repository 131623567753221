<template>
  <div
    class="app-container"
    v-loading.fullscreen.lock="fullscreenLoading"
    v-if="status"
  >
    <div class="top_select">
      <div>
        <span style="font-size: 14px;">请选择推送月份：</span>
        <qzf-search-period
          style="width:100px;"
          value-format="YYYYMM"
          v-model="period"
        >
        </qzf-search-period>
        <el-input
          placeholder="请输入企业名称"
          v-model="listQuery.name"
          style="width: 160px;margin-left: 10px;"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" size="small" @click="getList" style="margin-right: 5px;">
          <el-icon><Search /></el-icon><span> 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel" name="高级筛选" >
          <el-form
            style="width: 360px;"
            label-width="110px"
          >
            <el-form-item label="选择人员："> 
              <selectuser
                code="addCustomer"
                v-model:userId="listQuery.userId"
                style="width: 170px;"
              ></selectuser>
            </el-form-item>
            <el-form-item label="有无工资表："> 
              <el-select
                v-model="listQuery.salaryExist"
                placeholder="请选择有无工资表"
                :value-on-clear="null"
                size="small"
                style="width: 170px"
              >
                <el-option
                  v-for="item in sale"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="纳税人资格："> 
              <selecttaxtype
                v-model:type="listQuery.type"
                style="width: 170px"
              ></selecttaxtype>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button
          size="small"
          type="success"
          @click="freshPerson"
          icon="Refresh"
          :disabled="!$buttonStatus('qwfw_sxry')"
          plain
        >
          刷新人员
        </el-button>
        <el-button
          size="small"
          type="warning"
          @click="daoruFormVisible = true"
          icon="Upload"
          :disabled="!$buttonStatus('qwfw_drtxl')"
          plain
        >
          导入通讯录
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="beforeFreshGroup"
          icon="Refresh"
          :disabled="!$buttonStatus('qwfw_sxql')"
          plain
        >
          刷新群聊
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="pushWe"
          icon="Share"
          :disabled="!$buttonStatus('qwfw_qywxts')"
        >
          企业微信推送
        </el-button>
        <el-button
          size="small"
          type="success"
          @click="editName"
          icon="Edit"
          plain
          :disabled="!$buttonStatus('qwfw_xgqm')"
        >
          修改群名
        </el-button>
        <el-button
          size="small"
          type="primary"
          plain
          @click="getMsg"
          icon="ChatDotRound"
          :disabled="!$buttonStatus('qwfw_ckjl')"
        >
          查看记录
        </el-button>
      </div>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :height="contentStyleObj"
      v-loading="loading"
      @selection-change="handleSelectChange"
      stripe
    >
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column prop="comName" label="公司名称" fixed min-width="280">
        <template #default="scope">
          <TagNameCopy :row="scope?.row" :nameKey="'comName'" :needNameJump="false" :showAuthIcon="false">
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="superiorName" label="客户尊称" min-width="280">
      </el-table-column>
      <el-table-column prop="chatName" label="绑定群聊" min-width="200">
      </el-table-column>
      <el-table-column  label="会计" width="60" align="center">
        <template #default="scope" >
          <div>
            <el-popover placement="bottom" :width="330" trigger="click">
              <template #reference>
                <div @click="poper(scope.row.id)" style="cursor:pointer;">
                  <i class="iconfont icon-huiji"></i>
                </div>
              </template>
              <el-table :data="gridData" >
                <el-table-column width="100" align="center" property="cnName" label="负责人" />
                <el-table-column width="100" align="center" property="roleName" label="角色名称">
                  <template #default="scope">
                    <img
                      src="@/assets/tu/guan.png"
                      alt=""
                      v-if="scope.row.isLeader == 1"
                      style="width: 15px;height: 15px;margin-right: 5px;vertical-align: middle;"
                    />
                    <span style=" display: inline; vertical-align: middle;">{{ scope.row.roleName }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="100" align="center" property="deptName" label="部门名称" />
              </el-table>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="manager" label="操作" width="150">
        <template #default="scope">
          <el-button
            size="small"
            type="primary"
            @click="bind(scope.row)"
            :disabled="!$buttonStatus('qwfw_sz')"
            plain
            >设置</el-button
          >
          <!-- <el-button
            size="small"
            type="success"
            @click="editName(scope.row)"
            plain
            >修改原群名</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
        type="wecom"
      />
    </div>
  </div>
  <div v-else class="main">
    <div class="main_left">
      <img src="../../assets/qywx.png" />
    </div>
    <div class="main_right">
      <h3>
        企业微信<span style="color:var(--themeColor,#17a2b8)">未绑定</span
        >鲸算盘
      </h3>
      <h5>企微服功能亮点</h5>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>充分了解客户信息</p>
          <p>全局信息，随时掌控，精准服务</p>
        </div>
      </div>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>一对一精准沟通，提升品牌曝光度，感受VIP服务</p>
          <p>在线聊商务，回款更高效</p>
        </div>
      </div>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>账税信息同步信息高效秒回</p>
          <p>账，更贴近客户随时随地需要</p>
        </div>
      </div>
      <div class="each_content">
        <div>
          <el-icon>
            <circle-check-filled />
          </el-icon>
        </div>
        <div>
          <p>商机及投诉提醒</p>
          <p>
            设置关键词，商机自动提醒，防止客户流失，投诉及时同步，提升客户归属感
          </p>
        </div>
      </div>
      <div>
        <el-button type="primary" style="padding:5px 28px" @click="hint"
          >开通购买<el-icon style="margin-left: 3px;">
            <success-filled />
          </el-icon>
        </el-button>
      </div>
      <div style="font-size: 12px;margin-top: 10px;">
        还未注册企业微信？<span
          style="color:var(--themeColor,#17a2b8);cursor: pointer;"
          @click="hint"
          >去这里注册</span
        >
      </div>
    </div>
    <div class="pic">
      <img src="../../assets/qywxtop.png" />
    </div>
  </div>
  <wxRecord ref="wxRecord" />
  <wxRefresh ref="wxRefresh" @success="getList" />
  <!-- 税局小助手未安装提示 -->
  <loginTaxAlert ref="loginTaxAlert"></loginTaxAlert>
  <el-dialog
    :title="bindForm.comName + '-设置'"
    v-model="dialogFormVisible"
    width="30%"
  >
    <div v-loading="selectLoading">
      <el-form :model="form">
        <el-form-item label="客户尊称:" :label-width="formLabelWidth">
          <div v-if="!updateSuper">
            <span v-if="bindForm.superiorName">
              {{ bindForm.superiorName }}
            </span>
            <span v-else>--</span>
            <span
              class="shu_left"
              style="margin-left:20px"
              @click="updateSuper = true"
              >修改</span
            >|<span class="shu_left" @click="getName">获取</span>
          </div>
          <div v-else>
            <el-input
              v-model="bindForm.updateName"
              size="small"
              style="width:100px"
            ></el-input
            ><span class="shu_left" style="margin-left:20px" @click="saveName"
              >确认</span
            >|<span class="shu_left" @click="updateSuper = false">取消</span>
          </div>
        </el-form-item>
        <el-form-item label="群聊名称:" :label-width="formLabelWidth">
          <el-select
            v-model="bindForm.chatName"
            placeholder="请选择"
            size="small"
            filterable
            clearable
            style="width:80%"
            @change="handleChange"
          >
            <el-option
              v-for="item in options"
              :key="item"
              :label="toStr(item.name)"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="bindSure" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 推送 -->
  <el-dialog title="推送信息模板" v-model="dialogFormPushVisible" width="960px">
    <div class="title">模板推送</div>
    <el-radio-group v-model="msgTemplateId">
      <el-radio :label="1" border>续费提醒</el-radio>
      <el-radio :label="2" border>欠费提醒</el-radio>
      <el-radio :label="3" border>缴税提醒</el-radio>
      <el-radio :label="4" border>税款确认提醒</el-radio>
      <el-radio :label="5" border>薪酬确认提醒</el-radio>
      <el-radio :label="6" border>单据准备提醒</el-radio>
      <el-radio :label="7" border>个税申报提醒</el-radio>
    </el-radio-group>
    <div class="title" style="margin-top: 20px;">自定义</div>
    <el-input
      type="textarea"
      :rows="3"
      placeholder="请输入消息内容"
      v-model="userDefineContent"
    ></el-input>
    <div class="hint">
      <p style="color:red">
        <el-icon>
          <chat-line-round />
        </el-icon>
        注意事项
      </p>
      <p>1、群发消息时，需要开启税局小助手</p>
      <p>
        2、群发消息时，保证企业微信快捷键与其他应用不冲突(默认快捷键为Alt+Alt)
      </p>
      <p>3、群发消息时，不要动鼠标键盘</p>
      <p>4、群发消息时，把企业微信的窗口最小化隐藏掉</p>
      <p>5、不要一次群发过多的客户群聊(推荐发送100以下的客户数量)</p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormPushVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="pushSure" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 导入通讯录 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="daoruFormVisible"
    title="导入通讯录"
    width="40%"
  >
    <el-form size="small" label-position="right" label-width="5px">
      <el-form-item label="">
        <span style="color:red">*请在企业微信后台导出通讯录文件并上传</span>
      </el-form-item>
      <el-form-item label="">
        <el-input
          style="width: 60%; margin-right: 10px"
          v-model="path"
        ></el-input>
        <input
          size="small"
          ref="excel-upload-input"
          class="excel-upload-input"
          style="display: none"
          type="file"
          accept=".xlsx, .xls, .xml"
          @change="handleClick"
        />
        <el-button type="primary" @click="handleUpload()" size="small"
          >请选择文件</el-button
        >
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="daoruFormVisible = false"
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="uploadExcel()"
          :loading="daoruLoading"
          >上传</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 修改群聊名称 -->
   <!-- 推送 -->
   <el-dialog title="修改群聊名称" v-model="dialogEditVisible" width="400px">
    <div v-loading="selectLoading">
      <el-form size="small">
        <el-form-item label="原名称：">
          <el-select
            v-model="editForm.chatId"
            placeholder="请选择"
            size="small"
            filterable
            style="width: 180px;margin: 0;"
          >
            <el-option
              v-for="item in options"
              :key="item"
              :label="toStr(item.name)"
              :value="item.chat_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新名称：">
          <el-input v-model="editForm.newGroupName" style="width: 180px;"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogEditVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="editSure" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getChatList, bingChatCompany, getOrgCompanyList,companyUserList } from "@/api/company";
import {
  sendGroupMassMsg,
  getComSuperiorName,
  saveComSuperiorName,
  qywxCorpDeptRefresh,
  uploadContact,
  updateGroupName
} from "@/api/qywx";
import { currentAccountPeriod } from "@/utils";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectuser from "@/components/Screening/selectuser";
import axios from "axios";
import wxRecord from "./components/wxRecord.vue";
import wxRefresh from "./components/wxRefresh.vue";
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
export default {
  name: "wecom",
  components: {
    wxRecord,
    wxRefresh,
    selecttaxtype,
    TagNameCopy
  },
  data() {
    return {
      sale:[
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '有',
        },
        {
          value: 2,
          label: '无',
        },
      ],
      tableData: [],
      contentStyleObj: {},
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        userId: null,
        period: currentAccountPeriod(),
        salaryExist:null,
        type:'',
      },
      period: currentAccountPeriod(),
      total: 0,
      loading: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      options: [],
      selects: [],
      dialogFormPushVisible: false,
      msgTemplateId: null,
      userDefineContent: "",
      fullscreenLoading: false,
      selectLoading: false,
      bindForm: {},
      updateSuper: false,
      daoruFormVisible: false,
      rawFile: "",
      path: "",
      daoruLoading: false,
      status: this.$store.getters['user/user'].qywxStatus,
      editForm:{
        chatId:null,
        newGroupName:""
      },
      dialogEditVisible:false,
      gridData:[],//负责人数据
    };
  },
  created() {
    this.listQuery.limit = localStorage.getItem('wecom') ? localStorage.getItem('wecom')*1 : 20
    this.contentStyleObj = this.$getHeight(210);
    this.getList();
  },
  methods: {
    cancel(){
      this.listQuery = {
        page: 1,
        limit: this.listQuery.limit,
        name: "",
        userId: null,
        period: currentAccountPeriod(),
        salaryExist:null,
        type:'',
      }
      this.getList()
    },
    toStr(str) {
      let data;
      if (str) {
        data = encodeURI(str);
      } else {
        data = "暂无名称";
      }
      data = data.replace(/&nbsp;/g, "%20");
      return decodeURI(data);
    },
    getList() {
      this.loading = true;
      this.listQuery.period = this.period
      getOrgCompanyList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectChange(e) {
      this.selects = e;
    },
    bind(row) {
      this.dialogFormVisible = true;
      this.selectLoading = true;
      this.bindForm = Object.assign({}, row);
      this.bindForm.updateName = this.bindForm.superiorName;
      this.updateSuper = false;
      getChatList({}).then((res) => {
        this.selectLoading = false;
        if (res.data.msg == "success") {
          this.options = res.data.data.list;
        }
      });
    },
    handleChange(val) {
      this.bindForm.chatId = val.chat_id;
      this.bindForm.chatName = val.name;
    },
    getName() {
      getComSuperiorName({
        comId: this.bindForm.id,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.bindForm.updateName = res.data.data;
          this.updateSuper = true;
        }
      });
    },
    saveName() {
      saveComSuperiorName({
        comId: this.bindForm.id,
        superiorName: this.bindForm.updateName,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.bindForm.superiorName = this.bindForm.updateName;
          this.updateSuper = false;
          this.getList();
        }
      });
    },
    bindSure() {
      if (!this.bindForm.chatId) {
        this.$qzfMessage("请选择群聊", 1);
        return;
      }
      bingChatCompany({
        comId: this.bindForm.id,
        chatId: this.bindForm.chatId,
        comName: this.bindForm.comName,
        name: this.bindForm.chatName,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("绑定成功");
          this.dialogFormVisible = false;
          this.getList();
        }
      });
    },
    //企业微信推送
    pushWe() {
      if (this.selects.length == 0) {
        this.$qzfMessage("至少选择一家公司", 1);
        return;
      }
      let status = false;
      this.selects.map((v) => {
        if (!v.chatId) {
          status = true;
          return;
        }
      });
      if (status) {
        this.$qzfMessage("请选择已绑定群聊的公司", 1);
        return;
      }
      this.msgTemplateId = 0;
      this.userDefineContent = "";
      this.dialogFormPushVisible = true;
    },
    pushSure() {
      if (this.userDefineContent && this.msgTemplateId) {
        this.$qzfMessage("不可同时选择模板和自定义消息", 1);
        return;
      }
      let that = this;
      this.fullscreenLoading = true;
      let ids = [];
      this.selects.map((v) => {
        ids.push({
          comId: v.id,
          chatId: v.chatId,
          companyName: v.comName,
          superiorName: v.superiorName,
          period: this.period,
          userDefineContent: this.userDefineContent,
        });
      });
      let param = {
        msgPkg: ids,
        msgTemplateId: this.msgTemplateId,
      };
      sendGroupMassMsg(param).then((res) => {
        if (res.data.msg == "success") {
          let params = res.data.data;
          axios
            .post("http://localhost:9111/WxSend", params)
            .then((res) => {
              that.fullscreenLoading = false;
              if (res.data.msg == "success") {
                this.$qzfMessage("发送成功");
              } else {
                that.$alert(res.data.msg, "提示", {
                  confirmButtonText: "确定",
                });
              }
              that.dialogFormPushVisible = false;
            })
            .catch(function(e) {
              setTimeout(() => {
                that.$refs.loginTaxAlert.dialogVisible = true;
              }, 1000);
              // that.$alert("请先安装客户端", "提示", {
              //   confirmButtonText: "确定",
              // });
              that.fullscreenLoading = false;
              return;
            });
        } else {
          this.fullscreenLoading = false;
        }
      });
    },
    //刷新群聊
    beforeFreshGroup() {
      this.$refs.wxRefresh.init();
    },
    //刷新人员
    freshPerson() {
      this.loading = true;
      qywxCorpDeptRefresh({}).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage(res.data.data.data);
        }
      });
    },
    //查看记录
    getMsg() {
      this.$refs.wxRecord.getList();
    },
    //导入
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      uploadContact(param).then((res) => {
        this.daoruLoading = false;
        if (res.data.msg == "success") {
          this.daoruFormVisible = false;
          this.$qzfMessage("同步成功");
        }
      });
    },
    hint() {
      this.$qzfMessage("请联系客服人员");
    },
    //修改群名
    editName(){
      this.editForm.chatId = "";
      this.editForm.newGroupName = ""
      this.dialogEditVisible = true
      this.selectLoading = true
      getChatList({}).then((res) => {
        this.selectLoading = false;
        if (res.data.msg == "success") {
          this.options = res.data.data.list;
        }
      });
    },
    editSure(){
      if(!this.editForm.newGroupName){
        this.$qzfMessage("请输入新群名",1)
        return
      }
      updateGroupName(this.editForm).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage("修改成功")
          this.dialogEditVisible = false
          this.getList()
        }
      })
    },
    // 列表点击
    poper(val){
      this.gridData = []
      companyUserList({comId:val}).then(res=>{
        this.gridData = res.data.data.list
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 14px;
  border-left: 3px solid var(--themeColor, #17a2b8);
  padding-left: 5px;
  margin-bottom: 10px;
}
.hint {
  font-size: 14px;
  margin-top: 20px;
}
.el-radio {
  margin-top: 10px;
}
.shu_left {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.el-radio {
  margin-right: 10px !important;
}
.main {
  width: 100%;
  height: calc(100vh - 96px);
  position: relative;
  display: flex;
  align-items: center;
}
.main_left {
  width: 45%;
  height: 100%;
  background-image: linear-gradient(to right, #eff9fb, #fefeff);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90%;
  }
}
.main_right {
  float: left;
  h3 {
    margin-bottom: 15px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  h5 {
    margin-bottom: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
  }
}
.pic {
  width: 150px;
  position: absolute;
  top: 0;
  right: 0;
  img {
    width: 100%;
  }
}
.each_content {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .el-icon {
    font-size: 20px;
    color: #5aca8b;
    margin-right: 3px;
  }

  p {
    color: #474747;
    font-size: 14px;
  }
}
</style>
